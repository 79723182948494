import React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Social() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              linkedIn
              github
            }
          }
        }
      }
    `
  )

  return (
    <div>
      <a href={`https://twitter.com/${site.siteMetadata.social.twitter}`}>twitter</a>
      {` • `}
      <a href={`https://github.com/${site.siteMetadata.social.github}`}>github</a>
      {` • `}
      <a href={`https://linkedin.com/in/${site.siteMetadata.social.linkedIn}`}>linkedIn</a>
    </div>
  )
}

export default Social
